<template>
  <v-container>
    <v-row>
      <v-col>
        <v-autocomplete
          label="Periodo"
          item-text="nombre"
          item-value="id"
          :items="periodos"
          v-model="periodoId"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-autocomplete
          label="Grupos"
          item-text="nombre"
          item-value="id"
          :items="grupos"
          v-model="grupoId"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-autocomplete
          label="Estudiante"
          item-text="nombreCompleto"
          item-value="estudianteId"
          :items="alumnos"
          v-model="estudianteId"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="estudiantesGrupos"
          :items-per-page="5"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-dialog v-model="dialogTraslado" max-width="550">
              <v-card>
                <v-card-title>
                  Trasladar a :
                  <span class="headline"> {{ nombreTraslado }}</span>
                </v-card-title>
                <v-card-text>
                  <v-alert
                    border="bottom"
                    colored-border
                    type="warning"
                    elevation="2"
                  >
                    La información de las notas ingresadas se eliminaran si el
                    estudiante no pertenece a un grupo del mismo curso
                  </v-alert>

                  <v-autocomplete
                    label="Nuevo Grupo"
                    item-text="nombre"
                    item-value="id"
                    :items="gruposTraslados"
                    v-model="grupoTrasladoId"
                  ></v-autocomplete>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="trasladar" color="primary">Guardar</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-transit-transfer
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data: () => ({
    periodoId: 0,
    grupoId: 0,
    estudianteId: "-1",
    estudianteTraslado: "-1",
    nombreTraslado: "",
    grupoTrasladoId: 0,
    dialogTraslado: false,
    alumnos: [],
    periodos: [],
    grupos: [],
    headers: [
      {
        text: "Nombre Completo",
        value: "nombreCompleto",
      },
      { text: "Acciones", value: "actions", sortable: false },
    ],
  }),
  created() {
    this.initialize();
  },
  computed: {
    estudiantesGrupos() {
      if (this.estudianteId !== "-1") {
        return this.alumnos.filter((x) => {
          return x.estudianteId === this.estudianteId;
        });
      } else {
        return this.alumnos;
      }
    },
    gruposTraslados() {
      return this.grupos.filter((x) => {
        return x.id !== this.grupoId;
      });
    },
  },
  methods: {
    ...mapActions([
      "GetPeriodos",
      "GetGruposPeriodos",
      "GetAlumnosGrupoPerido",
      "UpdateGrupoPeriodoEstudiante",
      "ModificarMensaje",
    ]),
    initialize() {
      this.GetPeriodos().then((x) => (this.periodos = x.data));
    },
    editItem(item) {
      this.nombreTraslado = item.nombreCompleto;
      this.estudianteTraslado = item.estudianteId;
      this.dialogTraslado = true;
    },
    trasladar() {
      if (this.grupoTrasladoId > 0) {
        let datos = {};
        datos.grupo = this.grupoTrasladoId;
        datos.periodo = this.periodoId;
        datos.estudiante = this.estudianteTraslado;
        this.UpdateGrupoPeriodoEstudiante(datos).then((x) => {            
          if (!!x) {
              
            let index = this.alumnos
              .map(function (e) {
                return e.estudianteId;
              })
              .indexOf(datos.estudiante);
            this.alumnos.splice(index, 1);
            this.dialogTraslado = false;
            if(datos.estudiante === this.estudianteTraslado){
                this.estudianteId = "-1";
            }
            this.ModificarMensaje({ texto: "Traslado Exitoso!" });
            
          }else{
              this.ModificarMensaje({ texto: "No fue posilbe trasladarlo" });
          }
        });
      }
    },
  },
  watch: {
    periodoId(newValor) {
      if (newValor > 0) {
        this.GetGruposPeriodos(newValor).then((x) => (this.grupos = x.data));
      } else {
        this.alumnos = [];
        this.estudianteId = 0;
        this.grupos = [];
        this.grupoId = 0;
      }
    },
    grupoId(newValor) {
      if (newValor > 0) {
        let datos = {};
        datos.grupo = newValor;
        datos.periodo = this.periodoId;
        this.GetAlumnosGrupoPerido(datos).then((x) => (this.alumnos = x.data));
      } else {
        this.alumnos = [];
        this.estudianteId = 0;
      }
    },
    dialogTraslado(valor) {
      if (!valor) {
        this.estudianteTraslado = "-1";
        this.nombreTraslado = "";
        this.grupoTrasladoId = 0;
      }
    },
  },
};
</script>