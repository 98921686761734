<template>
    <TrasladoGrupo/>
</template>
<script>
import TrasladoGrupo from "@/components/TrasladoGrupo.vue";
export default {
    components: {
    TrasladoGrupo,
  },
}
</script>